import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/App.scss'
import { AuthContextProvider } from 'auth/AuthContext'
import ManageUsers from 'views/Pages/ManageUsers/ManageUsers'
import Metering from 'views/Pages/Metering/Metering'
import DynamicDashboardProvider from 'data/DynamicDashboardProvider'
import config from 'common/config'

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
)

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

const ManageAccount = React.lazy(() =>
  import('./views/Pages/ManageAccount/ManageAccounts')
)
const EditAccount = React.lazy(() =>
  import('./views/Pages/ManageAccount/EditAccount/EditAccount')
)
const UserInfo = React.lazy(() => import('./views/Pages/ManageUsers/UserInfo'))
const ManageProducts = React.lazy(() =>
  import('./views/Pages/Products/ManageProducts')
)
const Roles = React.lazy(() => import('./views/Pages/Roles/ManageRoles'))
const Register = React.lazy(() => import('./views/Pages/Register'))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500'))
const Dashboard = React.lazy(() => import('./views/Pages/Dashboard/Dashboard'))

const Definitions = React.lazy(() => import('./views/Pages/Definitions'))
// const DashboardWidgets = React.lazy(() =>
//   import('./views/Pages/Dashboard/DashboardWidgets')
// )
const GenerateReport = React.lazy(() => import('./views/Pages/GenerateReport'))
const ReportDOOH = React.lazy(() => import('./views/Pages/ReportDOOH'))
const NetworkGeomarkerReport = React.lazy(() => import('views/Pages/ReportNetworkGeomarker'))

class App extends Component {
  render () {
    return (
      <DynamicDashboardProvider>
        <AuthContextProvider>
          <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={props => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={props => <Page500 {...props} />}
                />
                <Route
                  exact
                  path="/app/splash"
                  name="Splash"
                  render={props => <DefaultLayout {...props} />}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={props => <Register {...props} />}
                />
                <Route
                  exact
                  path="/generate/report"
                  name="Generate Report"
                  render={props => <GenerateReport {...props} />}
                />
                <Route
                  exact
                  path="/manage-users"
                  name="Manage Users"
                  component={ManageUsers}
                />
                <Route
                  exact
                  path="/manage-account"
                  name="Admin Settings"
                  component={ManageAccount}
                />
                <Route
                  path="/user-info"
                  name="My Account"
                  render={props => <UserInfo {...props} />}
                />
                <Route
                  exact
                  path="/edit-account"
                  name="Edit Account"
                  render={props => <EditAccount {...props} />}
                />
                <Route
                  exact
                  path="/manage-products"
                  name="Products"
                  render={props => <ManageProducts {...props} />}
                />
                <Route
                  exact
                  path="/manage-roles"
                  name="Roles"
                  render={props => <Roles {...props} />}
                />
                <Route
                  exact
                  path="/metering"
                  name="Metering"
                  render={props => <Metering {...props} />}
                />
                <Route
                    exact
                    path="/definitions"
                    name="Definitions"
                    render={props => <Definitions {...props} />}
                />
                {config.Dashboard.enabled && <Route
                  path="/dashboard"
                  name="Dashboard"
                  render={props => <Dashboard {...props} />}
                />}
                {/*    <Route
                  path="/manage-notification"
                  name="Notification Configuration"
                  render={props => <ManageNotification {...props} />}
                />

                <Route
                  path="/viewWidgets"
                  name="DashboardWidgets"
                  render={props => <DashboardWidgets {...props} />}
                /> */}

                <Route
                  path="/report/dooh"
                  name="Report DOOH"
                  render={props => <ReportDOOH {...props} />}
                />

                <Route
                  path="/report/network"
                  name="Report Network Geomarker"
                  render={props => <NetworkGeomarkerReport {...props}/>}
                />

                <Route
                  path="/"
                  name="Home"
                  render={props => <DefaultLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </HashRouter>
        </AuthContextProvider>
      </DynamicDashboardProvider>
    )
  }
}

export default App
