import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  // NavItem
} from 'reactstrap'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import config from '../../common/config'

import {
  AppAsideToggler,
  // AppNavbarBrand,
  // AppSidebarToggler
} from '@coreui/react'

import {appAllowed, AuthContextConsumer} from 'auth/AuthContext'
import AdminLinks from './AdminLinks'
import EurekaBrandLogo from './EurekaBrandLogo'
import { destroyPinpointSession, destroyPinpointLocalStorage } from 'views/App/Pinpoint/constant'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {}

class DefaultHeader extends Component {
  constructor(props) {
    super(props)
    this.accountToggle = this.accountToggle.bind(this)
    this.state = {
      appDropdownOpen: false,
      accountDropdownOpen: false,
      notificationcount: 0,
    }
  }
  getUrl(baseUrl, pathToService) {
    baseUrl = baseUrl.endsWith('/')
      ? baseUrl.substring(0, baseUrl.length - 1)
      : baseUrl
    return baseUrl + pathToService
  }
  notificationUrl = () => {
    let notificationService
    if (
      'DefaultService' in config &&
      'notificationService' in config.DefaultService
    ) {
      notificationService = config.DefaultService.notificationService
    }
    if (notificationService === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Error...',
        text: 'Notification service is not specified in the default config',
      })
      return
    }
    let notificationUrl = this.getUrl(
      notificationService.url,
      notificationService.getNotificationUrl
    )
    return notificationUrl
  }
  accountToggle() {
    this.setState({
      accountDropdownOpen: !this.state.accountDropdownOpen,
    })
  }

  onLogout = (e, authState) => {
    e.preventDefault()
    sessionStorage.removeItem('token')

    this.props.history.push("/login"); //TODO enable this back when we have our login page
    if (authState.keycloak) {
      authState.keycloak.logout()
    }

    destroyPinpointSession() // destroy pinpoint session when user logged out
    destroyPinpointLocalStorage() // destroy pinpoint session when user logged out
  }
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props
    return (
      <AuthContextConsumer>
        {authState => {
          // const { appsAllowed, permissions, roles } = authState.user
          return (
            <React.Fragment>
              <EurekaBrandLogo selectedApp={this.props.selectedApp} />
              {/* !this.props.appDropDownDisable ? (
                <HeaderAppDropdown
                  apps={Apps.filter(app => {
                    const appNames = [
                      app.name,
                      ...(app.aliases ? app.aliases : [])
                    ]
                    return (
                      appsAllowed &&
                      appNames.some(name => appsAllowed.indexOf(name) >= 0)
                    )
                  })}
                  {...this.props}
                />
              ) : null */}

              <Nav className="d-md-down-none" navbar></Nav>
              <Nav className={`ml-auto ${config.DefaultService.notificationService.enabled ? "" : "last-child" }`} navbar>
                <Dropdown
                  nav
                  isOpen={this.state.accountDropdownOpen}
                  toggle={this.accountToggle}
                  direction="down"
                >
                  <DropdownToggle nav>
                    <img
                      src={'../../assets/img/avatars/9.png'}
                      className="img-avatar"
                      alt="admin@eureka.ai"
                    />
                  </DropdownToggle>
                  <DropdownMenu right style={{ right: 'auto',width: 190}}>
                    <DropdownItem header tag="div" className="text-center">
                      <strong>{authState.user.name}</strong>
                    </DropdownItem>
                    <NavLink to="/user-info">
                      <DropdownItem>
                        <i
                          className="fa fa-user-circle-o"
                          aria-hidden="true"
                        ></i>{' '}
                        My Account
                      </DropdownItem>
                    </NavLink>
                    <AdminLinks />
                    {config.DefaultService.notificationService.enabled && <NavLink to="/manage-notification">
                      <DropdownItem>
                          <i className="fa fa-bell"></i> Triggers
                      </DropdownItem>
                    </NavLink>}
                    <DropdownItem onClick={e => this.onLogout(e, authState)}>
                      <i className="fa fa-lock"></i> Logout
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
              {config.DefaultService.notificationService.enabled && <AppAsideToggler className="d-md-down-none" />}
            </React.Fragment>
          )
        }}
      </AuthContextConsumer>
    )
  }
}

DefaultHeader.propTypes = propTypes
DefaultHeader.defaultProps = defaultProps

export default DefaultHeader
