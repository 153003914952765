import React from 'react'
import AppComponent from '../AppComponent'

const GeoIntelligence = React.lazy(() => import('./GeoIntelligencev2'))


class Pinpoint extends AppComponent {
  static appDescriptor = {
    name: 'Pinpoint',
    url: '/app/pinpoint',
    icon: 'icon-pin',
    nav: {
      items: [
        {
          name: 'Geomarker',
          url: '/app/pinpoint/geomarker',
          icon: 'fas fa-map-marker',
          permissions: ['pinpoint:view']
        }
      ]
    },
    routes: [
      { path: '/app/pinpoint', exact: true, name: 'Pinpoint', component: GeoIntelligence },
      { path: '/app/pinpoint/geomarker', name: 'Geomarker', component: GeoIntelligence },
    ]
  }
  render () {
    return <div>Entering Eureka Pinpoint ...</div>
  }
}

export default Pinpoint
