import React, {useState} from 'react'
import config from "../../common/config";
import {
    AppHeaderDropdown,
    AppNavbarBrand,
    AppSidebarToggler
} from '@coreui/react'
import logo from '../../assets/img/brand/eureka_logo.png'
import sygnet from '../../assets/img/brand/eureka-brand.png'
import {DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
export default function EurekaBrandLogo (props) {
  const reload = 'http://' + window.location.hostname + ':' + window.location.port
  const [showDropdown, setShowDropdown] = useState(false)

  const handleSelect = (url) => {
      window.open(url, "_blank", "noreferrer");
      setShowDropdown(false)
  }

  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <AppNavbarBrand
        href={reload}
        full={{ src: logo, width: 89, height: 25, alt: 'Eureka Logo' }}
        minimized={{
          src: sygnet,
          width: 30,
          height: 30,
          alt: 'Eureka Logo'
        }}
      />

        {
            props.selectedApp.name === "Pinpoint" ? (
                <div id="custom-dropdown">
                    <div className="selector">
                        <div className="active-app" onClick={() => setShowDropdown(!showDropdown)}>PINPOINT Location Intelligence</div>
                        <div className="arrow">
                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                    </div>
                    {
                        showDropdown ? (
                            <>
                                {
                                    config.PinPoint.appList?.map((app) => {
                                        return (
                                            <div
                                                className="content-dropdown"
                                                onClick={() => handleSelect(app.url)}
                                            >
                                                {app.label}
                                            </div>
                                        )
                                    })
                                }
                            </>

                        ) : null
                    }
                </div>
            ) : null
        }
    </React.Fragment>
  )
}
